import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import { FiArrowDownCircle } from "react-icons/fi";
import developerLight from "../../images/developer.svg";
import developerDark from "../../images/developer-dark.svg";
import NguyenNhung from "../../images/nguyennhung.jpg";
import { motion } from "framer-motion";
import Button from "../reusable/Button";

const AppBanner = () => {
	const [activeTheme] = useThemeSwitcher();

	return (
		<motion.section
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
			className="flex flex-col sm:justify-between items-center sm:flex-row mt-12 md:mt-12"
		>
			<div className="w-full md:w-1/2 text-left">
				{/* <motion.h1
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: 'easeInOut',
						duration: 0.9,
						delay: 0.1,
					}}
					className="font-general-semibold font-bold text-2xl lg:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
				>
					Khương Văn Hiếu
				</motion.h1> */}
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeInOut",
						duration: 0.9,
						delay: 0.2,
					}}
					className="mt-4 font-bold text-md md:text-lg lg:text-xl xl:text-2xl text-left sm:text-left leading-normal text-gray-500 dark:text-gray-200"
				>
					🏡 Hà Nội:108 Nguyễn Trãi - Thanh Xuân
				</motion.p>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeInOut",
						duration: 0.9,
						delay: 0.2,
					}}
					className="mt-4 font-bold text-md md:text-lg lg:text-xl xl:text-2xl text-left sm:text-left leading-normal text-gray-500 dark:text-gray-200"
				>
					🏡 Sài Gòn: 36/32/14 Nguyễn Gia Trí (D2 cũ) Phường 25. Bình Thạnh
				</motion.p>

				{/* <motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: 'easeInOut',
						duration: 0.9,
						delay: 0.3,
					}}
					className="flex justify-center sm:block"
				>
				
							
				</motion.div> */}
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeInOut",
						duration: 0.9,
						delay: 0.3,
					}}
					className="mt-4 text-sm text-left sm:text-left leading-normal text-gray-500 dark:text-gray-200"
				>
					☎️ 𝙃𝙤𝙩𝙡𝙞𝙣𝙚 : 0968816688 ( Ms.Nhung )
				</motion.p>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeInOut",
						duration: 0.9,
						delay: 0.3,
					}}
					className="mt-4 text-sm text-left sm:text-left leading-normal text-gray-500 dark:text-gray-200"
				>
					Trợ lý : Linh 037 5856354
				</motion.p>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeInOut",
						duration: 0.9,
						delay: 0.3,
					}}
					className="mt-4 text-sm text-left sm:text-left leading-normal text-gray-500 dark:text-gray-200"
				>
					<div className="flex gap-2">
						<span
							type="button"
							className="px-4
							sm:px-6
							py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
							rounded-md
							focus:ring-1 focus:ring-indigo-900 duration-500"
							aria-label="Close Modal"
						>
							<a target="_blank" href="https://www.facebook.com/nhugkoy"><Button title="Facebook" /></a>

						</span>
						<span
							type="button"
							className="px-4
							sm:px-6
							py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
							rounded-md
							focus:ring-1 focus:ring-indigo-900 duration-500"
							aria-label="Close Modal"
						>
							<a target="_blank" href="https://www.instagram.com/nguyennhung.jellybeauty"><Button title="Instagram" /></a>
						</span>
						<span
							type="button"
							className="px-4
							sm:px-6
							py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
							rounded-md
							focus:ring-1 focus:ring-indigo-900 duration-500"
							aria-label="Close Modal"
						>
							<a target="_blank" href="https://www.tiktok.com/@nguyen_nhungjellybeauty"><Button title="Tiktok" /></a>

						</span>
						<span
							type="button"
							className="px-4
							sm:px-6
							py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
							rounded-md
							focus:ring-1 focus:ring-indigo-900 duration-500"
							aria-label="Close Modal"
						>
							<a target="_blank" href="https://zalo.me/84968816688"><Button title="Zalo" /></a>

						</span>
					</div>
				</motion.p>
			</div>
			<motion.div
				initial={{ opacity: 0, y: -180 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
				className="w-full sm:w-1/3 text-right float-right mt-8 sm:mt-0"
			>
				<img className="w-96 rounded-md" src={NguyenNhung} alt="NguyenNhung" />
			</motion.div>
		</motion.section>
	);
};

export default AppBanner;
