// Import images
import WebImage1 from '../images/web-project-1.jpg';
import WebImage2 from '../images/web-project-2.jpg';
import MobileImage1 from '../images/mobile-project-1.jpg';
import MobileImage2 from '../images/mobile-project-2.jpg';
import UIImage1 from '../images/ui-project-1.jpg';
import UIImage2 from '../images/ui-project-2.jpg';
import nangmui from '../images/nangmui.png';
import taohinhmat from '../images/taohinhmat.png'
import cangda from '../images/cangdamat.png'
import tiemfilter from '../images/tiemmatbaby.png';
import tiemmoi from '../images/tiemmoi.png';
import tiemmui from '../images/tiemnhomui.png';
import tiemgonham from '../images/tiemgonham.png';
import tiemxoanhan from '../images/tiemxoanhan.png';
import tiemmong from '../images/tiemmong.png';
import caychi from '../images/caychi.png';
import giambeo from '../images/giambeo.png';
import chamsocgia from '../images/chamsocda.png';
import Meraki from '../images/meraki.jpeg';
import Nghiatru from '../images/nghiatru.png';
import Quangninh from '../images/quangninh.jpeg';

export const projectsData = [
	{
		id: 1,
		title: 'Nâng mũi',
		category: 'Đất dự án',
		img: nangmui,
		detail: 'Nâng mũi bán cấu trúc, mũi cấu trúc vs những dáng mũi cực hot hiện nay : dáng mũi Trung Hoa, dáng mũi thái, mũi Sline, LLine …. Phù hợp vs từng gương mặt khác nhau đảm bảo sẽ làm bạn hài lòng',
		link: "https://www.facebook.com/media/set/?set=a.5936569243117730&type=3&mibextid=ncKXMA"
		
	},
	{
		id: 2,
		title: 'Tạo hình mắt',
		category: 'Đất dự án',
		img: taohinhmat,
		detail: "Cắt mí, mở góc mắt, kéo lệ trung hoa, mở tròng những pp cực đơn giản nhẹ nhàng để có 1 đôi hút hồn, tinh tế, khắc phục được mọi khuyết điểm về mắt",
		link: "https://www.facebook.com/media/set/?set=a.5936592316448756&type=3&mibextid=ncKXMA"
	},
	{
		id: 3,
		title: 'Căng da mặt toàn phần',
		category: 'Đất nền',
		img: cangda,
		detail: "Căng da mặt toàn phần là 1 tiểu phẫu vô cùng đơn giản nhẹ nhàng , loại bỏ hết phần da thừa nhăn nheo , chảy xệ giúp gương mặt căng mịn, hết nếp nhăn giúp bạn lấy lại chục tuổi thanh xuân.",
		link: "https://www.facebook.com/media/set/?set=a.5936603599780961&type=3&mibextid=ncKXMA"
	},
	{
		id: 4,
		title: 'Tiêm filler tạo hình mặt má baby',
		category: 'Đất nền',
		img: tiemfilter,
		detail: "Filler là 1 hợp chất vô cùng lành tính. Giúp làm đầy. Chỉnh má hóp, thái dương hóp, cằm ngắn, trán lẹm… an toàn , hiệu quả tức thì.",
		link: "https://www.facebook.com/media/set/?set=a.5936606646447323&type=3&mibextid=ncKXMA"
	},
	{
		id: 5,
		title: 'Tiêm môi',
		category: 'Đất dự án',
		img: tiemmoi,
		detail: "Cùng ngắm và lựa chọn những dáng môi hot nhất bên Nhung nha",
		link: "https://www.facebook.com/media/set/?set=a.5936619953112659&type=3&mibextid=ncKXMA"
		
	},
	{
		id: 6,
		title: 'Tiêm nhỏ đầu mũi',
		category: 'Đất dự án',
		img: tiemmui,
		detail: "Tiêm nhỏ đầu mũi giúp khắc phục tình trạng mũi to thô, Mũi to bẩm sinh hay mũi bao xơ sau nâng đều có thể thon nhỏ gọn nha🥰",
		link: "https://www.facebook.com/media/set/?set=a.5936651866442801&type=3&mibextid=ncKXMA"
	},
	{
		id: 7,
		title: 'Tiêm thon gọn hàm',
		category: 'Đất nền',
		img: tiemgonham,
		detail: "Đánh bay hàm to thô chỉ sau 1 lần tiêm. Lấy lại gương mặt nhỏ gọn thanh thoát, k còn hàm to hàm bạnh",
		link: "https://www.facebook.com/media/set/?set=a.5936792106428777&type=3&mibextid=ncKXMA"
	},
	{
		id: 8,
		title: 'Tiêm xoá nhăn',
		category: 'Đất nền',
		img: tiemxoanhan,
		detail: "Đánh bay nếp nhăn chỉ sau 1 lần tiêm, hiệu quả lâu dài. Trẻ ra chục tuổi",
		link: "https://www.facebook.com/media/set/?set=a.5936796706428317&type=3&mibextid=ncKXMA"
	},
	{
		id: 9,
		title: 'Tiêm mông',
		category: 'Đất dự án',
		img: tiemmong,
		detail: "Mông to mặc gì cũng đẹp. PP tiêm filler tạo hình mông. Giúp bạn có 1 cặp mông căng tròn đầy sức sống, an toàn hiệu quả",
		link: "https://www.facebook.com/media/set/?set=a.5936808933093761&type=3&mibextid=ncKXMA"
		
	},
	{
		id: 10,
		title: 'Cấy chỉ nâng cơ, chỉ colagen',
		category: 'Đất dự án',
		img: caychi,
		detail: "Cấy chỉ colagen bổ xung trực tiếp colagen dưới da giúp da săn chắc, sáng bóng, mờ thâm, mờ nám. 1 lần cấy chỉ chắc chắn bằng cả năm dưỡng da",
		link: "https://www.facebook.com/media/set/?set=a.2589418851166136&type=3&mibextid=ncKXMA"
	},
	{
		id: 11,
		title: 'Giảm béo',
		category: 'Đất nền',
		img: giambeo,
		detail: "Massa, bấm huyệt , siết eo: PP giảm béo cực an toàn. K cần uống thuốc, k cần nạo hút k tiêm chọc chỉ sau 1 liệu trình 10 bước giảm được số vòng đo như bạn mong muốn",
		link: "https://www.facebook.com/media/set/?set=a.5429009037207089&type=3&mibextid=ncKXMA"
	},
	{
		id: 12,
		title: 'Chăm sóc da',
		category: 'Đất nền',
		img: chamsocgia,
		detail: "Điều trị tất cả các bệnh lý về da: nám, mụn, tàn nhang da k đều màu giúp bạn có 1 nước da như ý",
		link: "https://www.facebook.com/media/set/?set=a.2082903738484319&type=3&mibextid=ncKXMA"
	}
];
