function AppFooterCopyright() {
	return (
		<div className="font-general-regular flex justify-center items-center text-center">
			<div className="text-sm text-ternary-dark dark:text-ternary-light">
				<h5
				
					className="hover:text-indigo-600 dark:hover:text-indigo-300 ml-1 duration-500"
				>
					Copyright © 2022 Dalton Technology
				</h5>
				
	
			</div>
		</div>
	);
}

export default AppFooterCopyright;
