// Import images
import Image1 from '../images/theemprire1.png';
import Image2 from '../images/theemprire2.png';
import Image3 from '../images/theemprire3.png';
import Image4 from '../images/mobile-project-1.jpg';
import Image5 from '../images/web-project-1.jpg';
import Image6 from '../images/ui-project-2.jpg';
import Meraki1 from '../images/meraki1.png';
import Meraki2 from '../images/meraki2.png';
import Meraki3 from '../images/meraki3.png';
import VanDon1 from '../images/vandon1.png';
import VanDon2 from '../images/vandon2.png';
import VanDon3 from '../images/vandon3.png';
import NghiaTru1 from '../images/nghiatru1.png';
import NghiaTru2 from '../images/nghiatru2.png';
import NghiaTru3 from '../images/nghiatru3.png';
// Import icons
import {
	FiFacebook,
	FiInstagram,
	FiLinkedin,
	FiTwitter,
	FiYoutube,
} from 'react-icons/fi';

export const singleProjectData = [{
	ProjectHeader: {
		title: 'Đại lý phân phối F1 Vinhomes The Emprire',
		publishDate: 'Tháng 4, 2022',
		tags: 'Đất dự án',
	},
	ProjectImages: [
		{
			id: 1,
			title: 'Vinhomes The Emprire',
			img: Image1,
		},
		{
			id: 2,
			title: 'Vinhomes The Emprire',
			img: Image2,
		},
		{
			id: 3,
			title: 'Vinhomes The Emprire',
			img: Image3,
		},
	],
	ProjectInfo: {
		ClientHeading: 'Chủ đầu tư',
		CompanyInfo: [
			{
				id: 1,
				title: 'Tên',
				details: 'Vinhomes - Vingroup',
			},
			{
				id: 2,
				title: 'Giá trung bình',
				details: '90 -120 tr/ 1m2',
			},
			
		],
		ObjectivesHeading: 'Vị trí',
		ObjectivesDetails:
			'Nghĩa Trụ, Văn Giang, Hưng Yên',
		Technologies: [
			{
				title: 'Tools & Technologies',
				techs: [
					'HTML',
					'CSS',
					'JavaScript',
					'Vue.js',
					'TailwindCSS',
					'AdobeXD',
				],
			},
		],
		ProjectDetailsHeading: 'Thông tin',
		ProjectDetails: [
			{
				id: 1,
				details:
					'Là giai đoạn 2 của siêu quần thể đô thị biển quy mô gần 1.000ha, Vinhomes Ocean Park - The Empire là thành phố kỳ quan duy nhất có công viên biển tạo sóng lớn nhất thế giới. Cách Vinhomes Ocean Park giai đoạn 1 chỉ 3 phút di chuyển, Vinhomes Ocean Park – The Empire là mảnh ghép hoàn chỉnh để tạo nên một siêu đô thị biển tầm cỡ thế giới sở hữu quần thể Siêu tiện ích quy mô - độc đáo - ấn tượng chưa từng có. ',
			},
			{
				id: 2,
				details:
					'Dự án Vinhomes Ocean Park -The Empire nằm trên địa bàn hai xã Long Hưng và Nghĩa Trụ, có ranh giới phía Bắc giáp đường Vành đai 3,5 theo quy hoạch, phía Tây giáp đường cao tốc Hà Nội - Hải Phòng, đường liên tỉnh Hà Nội - Hưng Yên thuộc huyện Văn Giang, tỉnh Hưng Yên. Với 2 tuyến giao thông quan trọng này, Vinhomes Ocean Park - The Empire sở hữu khả năng kết nối giao thông hoàn hảo. Từ đây cư dân dễ dàng di chuyển tới các tỉnh kế cận như Hải Dương, Hải Phòng, Bắc Ninh, Quảng Ninh.',
			},
			{
				id: 3,
				details:
					'Nằm kế cận trục đường giao thông quan trọng - cao tốc 5B Hà Nội – Hải Phòng mang đến lợi thế kết nối giao thông đắt giá giúp việc di chuyển về trung tâm hay kết nối vùng đều vô cùng thuận tiện. Ngoài ra, từ dự án cũng có thể đi theo tỉnh lộ 378 (dọc sông Hồng) để về phía Thủ đô.',
			},
			{
				id: 4,
				details:
					'Đáng chú ý, đường Vành đai 3,5 được quy hoạch nằm kế cận với lộ trình từ Quốc lộ 5 - đi qua dự Vinhomes Ocean Park - The Empire tới vị trí quy hoạch làm cầu Ngọc Hồi - Cao tốc Pháp Vân (Thanh Trì, Hà Nội) sẽ mang đến sự tăng trưởng vượt bậc cho toàn bộ khu vực trong tương lai.',
			},
		],
		SocialSharingHeading: 'Share This',
		SocialSharing: [
			{
				id: 1,
				name: 'Twitter',
				icon: <FiTwitter />,
				url: 'https://twitter.com/realstoman',
			},
			{
				id: 2,
				name: 'Instagram',
				icon: <FiInstagram />,
				url: 'https://instagram.com/realstoman',
			},
			{
				id: 3,
				name: 'Facebook',
				icon: <FiFacebook />,
				url: 'https://facebook.com/',
			},
			{
				id: 4,
				name: 'LinkedIn',
				icon: <FiLinkedin />,
				url: 'https://linkedin.com/',
			},
			{
				id: 5,
				name: 'Youtube',
				icon: <FiYoutube />,
				url: 'https://www.youtube.com/c/StomanStudio',
			},
		],
	},
	RelatedProject: {
		title: 'Related Projects',
		Projects: [
			{
				id: 1,
				title: 'Mobile UI',
				img: Image4,
			},
			{
				id: 2,
				title: 'Web Application',
				img: Image5,
			},
			{
				id: 3,
				title: 'UI Design',
				img: Image6,
			},
			{
				id: 4,
				title: 'Kabul Mobile App UI',
				img: Image3,
			},
		],
	},
},

{
	ProjectHeader: {
		title: 'Đại lý phân phối F1 Meraki Ecopark',
		publishDate: 'Tháng 7, 2022',
		tags: 'Đất dự án',
	},
	ProjectImages: [
		{
			id: 1,
			title: 'Meraki Ecopark',
			img: Meraki1,
		},
		{
			id: 2,
			title: 'Meraki Ecopark',
			img: Meraki2,
		},
		{
			id: 3,
			title: 'Meraki Ecopark',
			img: Meraki3,
		},
	],
	ProjectInfo: {
		ClientHeading: 'Chủ đầu tư',
		CompanyInfo: [
			{
				id: 1,
				title: 'Tên',
				details: 'Meraki Ecopark',
			},
			{
				id: 2,
				title: 'Giá trung bình',
				details: '90 -110 tr/ 1m2',
			},
			
		],
		ObjectivesHeading: 'Vị trí',
		ObjectivesDetails:
			'Ecopark, Văn Giang, Hưng Yên',
		Technologies: [
			{
				title: 'Tools & Technologies',
				techs: [
					'HTML',
					'CSS',
					'JavaScript',
					'Vue.js',
					'TailwindCSS',
					'AdobeXD',
				],
			},
		],
		ProjectDetailsHeading: 'Thông tin',
		ProjectDetails: [
			{
				id: 1,
				details:
					'Tổ hợp căn hộ chung cư Meraki Residences Ecopark nằm tại vị trí vô cùng đắc địa. Ngoài ra còn là một sản phẩm Kiến trúc tích hợp với mô hình Chăm Sóc Sức Khỏe – Biểu tượng gắn kết tình thân 🌷💕 ❤️… Tận hưởng dịch vụ chăm sóc y tế hiện đại tiêu chuẩn Nhật Bản với các gói dịch vụ dành cho người cao tuổi…',
			},
			{
				id: 2,
				details:
					'Meraki Residences Ecopark được định hình là Biểu tượng vượt thời gian. Sản phẩm kiến trúc với môi trường sống vào từng căn hộ và dịch vụ tiện ích khơi gợi cảm xúc và gắn kết tình thân…',
			},
			{
				id: 3,
				details:
					'Tiện ích công viên Hồ Thiên Nga, tổ hợp khoáng nóng Onsen tiêu chuẩn Nhật Bản, cung đường Vịnh Đảo shopping sầm uất trong bán kính 2km. Hệ thống 30 trường mầm non, trường PTLC Greenfield, trường PTLC Edison, trường quốc tế Chadwick, Đại học Anh Quốc BUV, Đại học Y khoa Tokyo. Chỉ cách Trung tâm quận Hoàn Kiếm 14km, Ecopark là KĐT sinh thái, hiện đại nhất Việt Nam với các tiện ích vượt trội cho gia đình 3 thế hệ.',
			},
			{
				id: 4,
				details:
					'Tại Meraki Residences, cư dân sẽ được chăm sóc sức khỏe 24/7 bởi thương hiệu bệnh viện đến từ Tokyo (Nhật Bản), với các gói thăm khám định kỳ bởi đội ngũ 100% bác sĩ Nhật. Ngoài ra, cư dân còn được tham gia các chương trình đào tạo chăm sóc sức khỏe về dinh dưỡng chuyên sâu, nấu ăn, chăm sóc người cao tuổi… Điểm đặc biệt nhất của dự án đó là hệ thống cấp cứu sẽ kết nối với bệnh viện trong vòng 5 phút, thông qua các nút bấm khẩn cấp được trang bị quanh tòa nhà và trong từng không gian căn hộ tại phòng khách, phòng ngủ và phòng tắm.',
			},
		],
		SocialSharingHeading: 'Share This',
		SocialSharing: [
			{
				id: 1,
				name: 'Twitter',
				icon: <FiTwitter />,
				url: 'https://twitter.com/realstoman',
			},
			{
				id: 2,
				name: 'Instagram',
				icon: <FiInstagram />,
				url: 'https://instagram.com/realstoman',
			},
			{
				id: 3,
				name: 'Facebook',
				icon: <FiFacebook />,
				url: 'https://facebook.com/',
			},
			{
				id: 4,
				name: 'LinkedIn',
				icon: <FiLinkedin />,
				url: 'https://linkedin.com/',
			},
			{
				id: 5,
				name: 'Youtube',
				icon: <FiYoutube />,
				url: 'https://www.youtube.com/c/StomanStudio',
			},
		],
	},
	RelatedProject: {
		title: 'Related Projects',
		Projects: [
			{
				id: 1,
				title: 'Mobile UI',
				img: Image4,
			},
			{
				id: 2,
				title: 'Web Application',
				img: Image5,
			},
			{
				id: 3,
				title: 'UI Design',
				img: Image6,
			},
			{
				id: 4,
				title: 'Kabul Mobile App UI',
				img: Image3,
			},
		],
	},
},

{
	ProjectHeader: {
		title: 'Đất nền Nghĩa Trụ, Văn Giang - Cạnh dự án Vinhomes 2 + 3',
		publishDate: 'Tháng 2, 2020',
		tags: 'Đất nền',
	},
	ProjectImages: [
		{
			id: 1,
			title: 'Nghĩa Trụ, Văn Giang',
			img: NghiaTru1,
		},
		{
			id: 2,
			title: 'Nghĩa Trụ, Văn Giang',
			img: NghiaTru2,
		},
		{
			id: 3,
			title: 'Nghĩa Trụ, Văn Giang',
			img: NghiaTru3,
		},
	],
	ProjectInfo: {
		ClientHeading: 'Chủ đầu tư',
		CompanyInfo: [
			{
				id: 1,
				title: 'Tên',
				details: 'Đất nền Nghĩa Trụ, Văn Giang, Hưng Yên',
			},
			{
				id: 2,
				title: 'Giá trung bình',
				details: '40 -100 tr/ 1m2',
			},
			
		],
		ObjectivesHeading: 'Vị trí',
		ObjectivesDetails:
			'Nghĩa Trụ, Văn Giang, Hưng Yên',
		Technologies: [
			{
				title: 'Tools & Technologies',
				techs: [
					'HTML',
					'CSS',
					'JavaScript',
					'Vue.js',
					'TailwindCSS',
					'AdobeXD',
				],
			},
		],
		ProjectDetailsHeading: 'Thông tin',
		ProjectDetails: [
			{
				id: 1,
				details:
					'Nghĩa Trụ là một xã thuộc phía đông bắc huyện Văn Giang, tỉnh Hưng Yên, Việt Nam.',
			},
			{
				id: 2,
				details:
					'Xã Nghĩa Trụ được chia thành 9 thôn: Tam Kỳ, Phúc Thọ, Đại Tài, Đồng Tỉnh, Nhân Thục, Nhân Vực, Thủ Pháp, Lê Cao và thôn 14 cách xa xã nhất.',
			},
			{
				id: 3,
				details:
					'Nằm giữa 2 đường quốc lộ 5A, 5B nên được các chủ đầu tư quan tâm, tiêu biểu là dự án Vinhomes 2, Vinhomes 3 cùng nằm trong khu vực.',
			},
			{
				id: 4,
				details:
					'Tiềm năng tăng giá lớn, xung quanh là các đại đô thị đang chuyển dần về, đặc biệt là chính sách mở rộng về phía Đông của nhà nước trong những năm gần đây.',
			},
		],
		SocialSharingHeading: 'Share This',
		SocialSharing: [
			{
				id: 1,
				name: 'Twitter',
				icon: <FiTwitter />,
				url: 'https://twitter.com/realstoman',
			},
			{
				id: 2,
				name: 'Instagram',
				icon: <FiInstagram />,
				url: 'https://instagram.com/realstoman',
			},
			{
				id: 3,
				name: 'Facebook',
				icon: <FiFacebook />,
				url: 'https://facebook.com/',
			},
			{
				id: 4,
				name: 'LinkedIn',
				icon: <FiLinkedin />,
				url: 'https://linkedin.com/',
			},
			{
				id: 5,
				name: 'Youtube',
				icon: <FiYoutube />,
				url: 'https://www.youtube.com/c/StomanStudio',
			},
		],
	},
	RelatedProject: {
		title: 'Related Projects',
		Projects: [
			{
				id: 1,
				title: 'Mobile UI',
				img: Image4,
			},
			{
				id: 2,
				title: 'Web Application',
				img: Image5,
			},
			{
				id: 3,
				title: 'UI Design',
				img: Image6,
			},
			{
				id: 4,
				title: 'Kabul Mobile App UI',
				img: Image3,
			},
		],
	},
},


{
	ProjectHeader: {
		title: 'Đầu tư đất nền Vân Đồn, Quảng Ninh',
		publishDate: 'Tháng 7, 2020',
		tags: 'Đất nền',
	},
	ProjectImages: [
		{
			id: 1,
			title: 'Đất nền Vân Đồn',
			img: VanDon1,
		},
		{
			id: 2,
			title: 'Đất nền Vân Đồn',
			img: VanDon2,
		},
		{
			id: 3,
			title: 'Đất nền Vân Đồn',
			img: VanDon3,
		},
	],
	ProjectInfo: {
		ClientHeading: 'Chủ đầu tư',
		CompanyInfo: [
			{
				id: 1,
				title: 'Tên',
				details: 'Đất nền Vân Đồn, Quảng Ninh',
			},
			{
				id: 2,
				title: 'Giá trung bình',
				details: '60 -100 tr/ 1m2',
			},
			
		],
		ObjectivesHeading: 'Vị trí',
		ObjectivesDetails:
			'Vân Đồn , Quảng Ninh',
		Technologies: [
			{
				title: 'Tools & Technologies',
				techs: [
					'HTML',
					'CSS',
					'JavaScript',
					'Vue.js',
					'TailwindCSS',
					'AdobeXD',
				],
			},
		],
		ProjectDetailsHeading: 'Thông tin',
		ProjectDetails: [
			{
				id: 1,
				details:
					'Huyện Vân Đồn nằm ở phía đông tỉnh Quảng Ninh, là một vùng biển nằm trong vịnh Bắc Bộ với 600 hòn đảo lớn nhỏ thuộc hai quần đảo Cái Bầu (tên cũ là Kế Bào) và Vân Hải. Đảo lớn nhất và tập trung đông dân nhất của huyện là đảo Cái Bầu (tên cũ là Kế Bào) với diện tích tự nhiên 17.212 ha, cách đất liền qua lạch biển Cửa Ông và sông Voi Lớn. Huyện lỵ của huyện là thị trấn Cái Rồng nằm trên đảo Cái Bầu, cách thành phố Hạ Long khoảng 50 km, cách Cửa Ông 7 km theo đường tỉnh lộ 334 qua cầu Vân Đồn[6]. Quần đảo Vân Hải nằm ở rìa phía đông nam huyện, gồm các đảo lớn như: Trà Bản, Ba Mùn (Cao Lô), Cảnh Cước (Quan Lạn), Đống Chén, Thẻ Vàng, Ngọc Vừng,... và hàng trăm đảo nhỏ khác. Các đảo đều có địa hình núi đá vôi, thường chỉ cao 200–300 m so với mặt biển, có nhiều hang động karst.',
			},
			{
				id: 2,
				details:
					'Huyện Vân Đồn có diện tích 581,8 km²[3], dân số năm 2019 là 46.616 người[4], tập trung chủ yếu ở thị trấn Cái Rồng và các xã Đông Xá, Hạ Long, Quan Lạn.',
			},
			{
				id: 3,
				details:
					'Do địa hình là quần đảo chủ yếu là các đảo nhỏ, lại là núi đá vôi, nên trong toàn bộ diện tích tự nhiên của huyện, diện tích đất liền không lớn, chủ yếu là diện tích mặt biển. Huyện đảo Vân Đồn có 68% diện tích đất tự nhiên trên các đảo là rừng và đất rừng. Trên các đảo không có sông ngòi lớn mà chỉ có vài con suối trên những đảo lớn.',
			},
			{
				id: 4,
				details:
					'Từ đất liền có thể sang đảo Cái Bầu bằng đường bộ đi qua 3 cây cầu Vân Đồn I, Vân Đồn II và Vân Đồn III. Tỉnh lộ 334 dài 40 km nối tiếp các cây cầu trên và chạy xuyên suốt 40 km trong đảo Cái Bầu. Tuy nhiên, giao thông trong huyện chủ yếu là bằng đường thủy giữa các đảo. Xã Vạn Yên (trên đảo Cái Bầu) có bến cảng Vạn Hoa, thị trấn Cái Rồng (trên đảo Cái Bầu) có cảng Cái Rồng, có thể cho xà lan, tàu thuyền tải trọng hàng trăm tấn ra vào dễ dàng, đồng thời là đầu mối giao thông qua lại giữa các đảo. Năm 2020, khai trươngđường cao tốc Hạ Long - Vân Đồn đi qua. Ở đây còn có sân bay Vân Đồn hiện đã được đưa vào khai thác.',
			},
			,
			{
				id: 5,
				details:
					'Trên các đảo Trà Bản, Ngọc Vừng, Quan Lạn có những đoạn đường ô tô dùng cho quân sự và lâm nghiệp, nhưng chỉ là các đoạn đường trong nội bộ các đảo. Còn giữa các đảo với nhau và với đất liền chủ yếu vẫn dùng phương tiện giao thông đường thủy.',
			},
		],
		SocialSharingHeading: 'Share This',
		SocialSharing: [
			{
				id: 1,
				name: 'Twitter',
				icon: <FiTwitter />,
				url: 'https://twitter.com/realstoman',
			},
			{
				id: 2,
				name: 'Instagram',
				icon: <FiInstagram />,
				url: 'https://instagram.com/realstoman',
			},
			{
				id: 3,
				name: 'Facebook',
				icon: <FiFacebook />,
				url: 'https://facebook.com/',
			},
			{
				id: 4,
				name: 'LinkedIn',
				icon: <FiLinkedin />,
				url: 'https://linkedin.com/',
			},
			{
				id: 5,
				name: 'Youtube',
				icon: <FiYoutube />,
				url: 'https://www.youtube.com/c/StomanStudio',
			},
		],
	},
	RelatedProject: {
		title: 'Related Projects',
		Projects: [
			{
				id: 1,
				title: 'Mobile UI',
				img: Image4,
			},
			{
				id: 2,
				title: 'Web Application',
				img: Image5,
			},
			{
				id: 3,
				title: 'UI Design',
				img: Image6,
			},
			{
				id: 4,
				title: 'Kabul Mobile App UI',
				img: Image3,
			},
		],
	},
},

];
