import { FiGithub, FiTwitter, FiInstagram, FiBook } from 'react-icons/fi';
import AppFooterCopyright from './AppFooterCopyright';

const socialLinks = [
	{
		id: 1,
		icon: <FiGithub/>,
		url: 'https://github.com/realstoman',
	},
	{
		id: 2,
		icon: <FiTwitter />,
		url: 'https://twitter.com/realstoman',
	},
	// {
	// 	id: 3,
	// 	icon: <FiBook />,
	// 	url: 'https://stoman.medium.com',
	// },
	// {
	// 	id: 4,
	// 	icon: <FiInstagram />,
	// 	url: 'https://instagram.com/realstoman',
	// },
];

const AppFooter = () => {
	return (
		<div className="container mx-auto">
			<div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
				

				<AppFooterCopyright />
			</div>
		</div>
	);
};

export default AppFooter;
