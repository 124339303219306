// NOTE: This scroll to top is the actual working scroll to to when user clicks on the circle arrow that appears when use scrolls down.
// The other `ScrollToTop` component in components folder is for the default react scroll to top behavior on route visit.

import { useState, useEffect } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import PhoneIcon from '../images/telephone.png';

const Phone = () => {
	const [showScroll, setShowScroll] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', scrollToTop);
		return function cleanup() {
			window.removeEventListener('scroll', scrollToTop);
		};
	});

	const scrollToTop = () => {
		if (!showScroll && window.pageYOffset > 400) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= 400) {
			setShowScroll(false);
		}
	};

	const backToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', scrollToTop);

	return (
		<>
		{/* <img src={require('../images/phone.png')}  alt="Image1"/> */}

			{/* <PhoneIcon></PhoneIcon> */}
			<a target="_blank" href="tel:0968816688"><img
				src={PhoneIcon}
				className="scrollToTop"

				style={{
					height: 50,
					width: 50,
					borderRadius: 50,
					left: 50,
					bottom: 50,
					display: showScroll ? 'flex' : 'none',
					padding: 5,
				}}
			/>
			</a>
		</>
	);
};

export default Phone;
